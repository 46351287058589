import React from 'react';
import { HiOutlineSparkles, HiOutlineBolt, HiOutlineFilm, HiDevicePhoneMobile } from 'react-icons/hi2';

const BulletPointsSection = () => {
  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 py-2 hidden md:block">
      <div className="flex font-bold flex-col items-center sm:flex-row sm:justify-center sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 py-8">
        <div className="flex w-full max-w-xs items-center space-x-2">
          <HiDevicePhoneMobile className="h-6 w-6 text-black" />
          <span className="text-black text-left">Sized for Socials</span>
        </div>
        <div className="flex w-full max-w-xs items-center space-x-2">
          <HiOutlineSparkles className="h-6 w-6 text-black" />
          <span className="text-black text-left">Proven Virality</span>
        </div>
        <div className="flex w-full max-w-xs items-center space-x-2">
          <HiOutlineBolt className="h-6 w-6 text-black" />
          <span className="text-black text-left">Videos in Minutes</span>
        </div>
        <div className="flex w-full max-w-xs items-center space-x-2">
          <HiOutlineFilm className="h-6 w-6 text-black" />
          <span className="text-black text-left">Highest Resolution AI</span>
        </div>
      </div>
    </div>
  );
};

export default BulletPointsSection;
