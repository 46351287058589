import { useState } from 'react';
import { Dialog, DialogPanel } from '@headlessui/react';
import { HiBars3, HiMiniXMark } from "react-icons/hi2";

import { Link, useNavigate } from 'react-router-dom';
import Logo from '../icons/vslogo.png';

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleNavigation = (key) => {
    switch (key) {
      case 'Pricing':
        document.getElementById('pricing-sec').scrollIntoView({ behavior: 'smooth' });
        break;
      case 'Examples':
        document.getElementById('video-slider').scrollIntoView({ behavior: 'smooth' });
        break;
      case 'FAQ':
        document.getElementById('faqs').scrollIntoView({ behavior: 'smooth' });
        break;
      default:
        break;
    }
  };

  const navigation = [
    { name: 'Pricing', onClick: () => handleNavigation('Pricing') },
    { name: 'Examples', onClick: () => handleNavigation('Examples') },
    { name: 'FAQ', onClick: () => handleNavigation('FAQ') },
    { name: 'Affiliate', onClick: () => navigate('/affiliate') }, // Added Affiliate tab
  ];

  return (
    <header className="bg-white">
      <nav aria-label="Global" className="mx-auto flex max-w-7xl items-center justify-between gap-x-6 p-6 lg:px-8">
        <div className="flex lg:flex-1">
          <a href="#" className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <img alt="Your Company Logo" src={Logo} className="h-12 w-auto" />
          </a>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <button key={item.name} onClick={item.onClick} className="text-sm font-semibold leading-6 text-gray-900">
              {item.name}
            </button>
          ))}
        </div>
        <div className="flex flex-1 items-center justify-end gap-x-6">
          <Link to="/login" className="hidden lg:block lg:text-sm lg:font-semibold lg:leading-6 lg:text-gray-900">
            Log in
          </Link>
          <button
            className="rounded-md bg-black px-3 py-[5px] text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
            onClick={() => handleNavigation('Pricing')}
          >
            Start Now
          </button>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <HiBars3 aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
      </nav>
      <Dialog
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
        className="lg:hidden"
        portal={false} // Disable portal behavior
      >
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center gap-x-6">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img alt="Your Company Logo" src={Logo} className="h-14 w-auto" />
            </a>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Close menu</span>
              <HiMiniXMark aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="py-6">
                <button
                  onClick={() => {
                    navigate('/login');
                    setMobileMenuOpen(false);
                  }}
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Log in
                </button>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
};

export default Header;
