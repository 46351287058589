import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import awsConfig from '../awsConfig';
import Logo from '../icons/vslogo.png';

const userPool = new CognitoUserPool({
  UserPoolId: awsConfig.UserPoolId,
  ClientId: awsConfig.ClientId,
});

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isCodeVerified, setIsCodeVerified] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        console.log('login success', result);
        const idToken = result.getIdToken().getJwtToken();
        const accessToken = result.getAccessToken().getJwtToken();
        const refreshToken = result.getRefreshToken().getToken();
      
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);

        fetch('https://api.vidstudio.ai/update-tokens', { 
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: email, 
            idToken: idToken,
            accessToken: accessToken,
            refreshToken: refreshToken,
          }),
        })
        .then(response => response.json())
        .then(data => console.log('Server response:', data))
        .catch(error => console.error('Error sending tokens to server:', error));

        navigate('/dashboard/generate');
      },
      
      onFailure: (err) => {
        console.error('Login failed', err);
        alert(err.message || JSON.stringify(err));
      },
    });
  };

  const handleForgotPassword = () => {
    const cognitoUser = new CognitoUser({
      Username: forgotPasswordEmail,
      Pool: userPool,
    });

    cognitoUser.forgotPassword({
      onSuccess: (data) => {
        console.log('Forgot password success', data);
        alert('Password reset code sent to your email.');
        setIsCodeSent(true);
      },
      onFailure: (err) => {
        console.error('Forgot password failed', err);
        alert(err.message || JSON.stringify(err));
      },
    });
  };

  const handleVerifyCode = () => {
    if (newPassword !== confirmPassword) {
      alert('Passwords do not match.');
      return;
    }

    const cognitoUser = new CognitoUser({
      Username: forgotPasswordEmail,
      Pool: userPool,
    });

    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: () => {
        console.log('Password reset success');
        alert('Password has been reset successfully.');
        setIsForgotPassword(false);
        setIsCodeSent(false);
        setIsCodeVerified(false);
      },
      onFailure: (err) => {
        console.error('Password reset failed', err);
        alert(err.message || JSON.stringify(err));
      },
    });
  };

  return (
    <div className="flex flex-col min-h-screen justify-center items-center bg-white">
      <div className="absolute top-4 left-4">
        <img 
          src={Logo} 
          alt="Logo" 
          className="h-12 w-auto cursor-pointer" 
          onClick={() => navigate('/')}
        />
      </div>
      <div className="w-full max-w-md px-6">
        <h2 className="text-2xl font-extrabold text-gray-900">Welcome Back!</h2>
        <h3 className="mt-2 text-lg font-extrabold text-gray-900">Log In</h3>
        <p className="mt-1 text-sm text-gray-600">
          New here? <a href="/register" className="underline text-blue-600">Create an account</a>
        </p>
        <div className="mt-4">
          <form onSubmit={handleSubmit} className="space-y-4">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
              className="w-full px-5 py-2 placeholder-gray-400 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 text-black"
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
              className="w-full px-5 py-2 placeholder-gray-400 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 text-black"
            />
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Log In
            </button>
          </form>
          <div className="mt-2">
            <button
              onClick={() => setIsForgotPassword(true)}
              className="underline text-blue-600"
            >
              Forgot Password?
            </button>
          </div>
          {isForgotPassword && (
            <div className="mt-2">
              <input
                type="email"
                value={forgotPasswordEmail}
                onChange={(e) => setForgotPasswordEmail(e.target.value)}
                placeholder="Enter your email"
                required
                className="w-full px-5 py-3 placeholder-gray-400 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 text-black"
              />
              {!isCodeSent && (
                <button
                  onClick={handleForgotPassword}
                  className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-2"
                >
                  Send Password Reset Email
                </button>
              )}
              {isCodeSent && !isCodeVerified && (
                <>
                  <input
                    type="text"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    placeholder="Enter verification code"
                    required
                    className="w-full px-5 py-3 placeholder-gray-400 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 text-black mt-2"
                  />
                  <button
                    onClick={() => setIsCodeVerified(true)}
                    className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-2"
                  >
                    Submit Code
                  </button>
                </>
              )}
              {isCodeVerified && (
                <>
                  <input
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="Enter new password"
                    required
                    className="w-full px-5 py-3 placeholder-gray-400 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 text-black mt-2"
                  />
                  <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm new password"
                    required
                    className="w-full px-5 py-3 placeholder-gray-400 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 text-black mt-2"
                  />
                  <button
                    onClick={handleVerifyCode}
                    className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-2"
                  >
                    Submit New Password
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
