/* global fbq */
/* global ttq */


import React, { useEffect } from 'react';
import HeroSection from '../components/Landing/HeroSection';
import BulletPointsSection from '../components/Landing/BulletPointsSection';
import TutorialSection from '../components/Landing/TutorialSection';
import FAQs from '../components/Landing/FAQs';
import Footer from '../components/Landing/Footer';
import VideoSlider from '../components/Landing/VideoSlider';
import VidStats from '../components/Landing/VidStats.js';
import { CTA1, CTA2, CTA3 } from '../components/Landing/CTA.js';
import Header from '../components/Header.js';
import Pricing from '../components/Pricing.js';
import Banner from '../components/Landing/Banner.js';


const LandingPage = () => {
  useEffect(() => {
    if (typeof fbq === 'function') {
      fbq('track', 'PageView');
    }
    if (window.ttq) {
      window.ttq.track('ViewContent', {
        contents: [
          {
            content_id: "101",
            content_type: "product",
            content_name: "StudyBot"
          }
        ],
        value: 0,
        currency: "USD"
      });
    }
  }, []);

  return (
    <div className="relative bg-white">
      <Banner />
      <Header />
      <HeroSection />
      <BulletPointsSection />
      <div id="video-slider">
        <VideoSlider />
      </div>
      <CTA1 />
      <TutorialSection />
      <VidStats />
      <div id="pricing-sec">
        <Pricing />
      </div>
      <CTA2 />
      <div id="faqs">
        <FAQs />
      </div>
      <CTA3 />
      <Footer />
    </div>
  );
};

export default LandingPage;
