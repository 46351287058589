// src/components/VideoTutorials.js
import React, { useState } from 'react';

const VideoTutorials = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const videoData = [
    {
      url: "https://delivery626.nyc3.cdn.digitaloceanspaces.com/compressedvids/pianist.mp4",
      prompt: "Male pianist playing piano",
      fillContainer: true
    },
    {
      url: "https://delivery626.nyc3.cdn.digitaloceanspaces.com/compressedvids/0923(6)_comp.mp4",
      prompt: "Man standing next to erupting volcano"
    },
    {
      url: "https://delivery626.nyc3.cdn.digitaloceanspaces.com/compressedvids/0923_comp.mp4",
      prompt: "Colorful fish in ocean, coral reefs in the background and other fish swimming. I want to see yellow fish and blue fish."
    },
    {
      url: "https://delivery626.nyc3.cdn.digitaloceanspaces.com/compressedvids/0923(2)_comp.mp4",
      prompt: "Sailing ship"
    }
  ];

  const handleMouseEnter = (e) => {
    if (!isMobile) {
      e.target.play();
    }
  };

  const handleMouseLeave = (e) => {
    if (!isMobile) {
      e.target.pause();
      e.target.currentTime = 0;
    }
  };

  const handleClick = (e) => {
    if (isMobile) {
      if (e.target.paused) {
        e.target.play();
      } else {
        e.target.pause();
        e.target.currentTime = 0;
      }
    }
  };

  return (
    <div className="relative px-4 md:px-8 py-16 overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl md:text-4xl font-bold mb-6 text-gray-900 text-center">
          Need some <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-blue-600">inspiration?</span>
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {videoData.map((video, index) => (
            <div key={index} className="flex flex-col">
              <div className="relative rounded-lg overflow-hidden shadow-lg transform transition duration-300 hover:scale-105">
                <video
                  className={`w-full h-auto ${video.fillContainer ? 'object-cover' : 'aspect-[9/16]'}`}
                  loop
                  muted
                  playsInline
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={handleClick}
                >
                  <source src={video.url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="mt-4 bg-white rounded-lg p-4 shadow-md">
                <p className="text-sm font-medium text-gray-900 mb-1">Prompt:</p>
                <p className="text-sm text-gray-700">"{video.prompt}"</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VideoTutorials;
