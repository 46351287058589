import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white text-center py-4">
      &copy; 2024 VidStudio AI. All rights reserved.
      <div>
        <Link to="/privacy" className="text-white hover:underline">Privacy Policy</Link>
        <a href="mailto:support@vidstudio.ai" className="text-white hover:underline ml-4">Contact Us</a>
      </div>
    </footer>
  );
};

export default Footer;
