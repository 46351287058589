import React from 'react';

const HeroVideoShowcase = () => {
  return (
    <div className="video-showcase relative flex justify-center items-center h-[400px]">
      {/* Video 1 */}
      <div className="absolute transform -rotate-12 -translate-x-40 z-5">
        <video className="h-[300px] w-[168px] aspect-[9/16] border-2 border-green-800 rounded-md shadow-lg" autoPlay loop muted playsInline>
          <source src="https://delivery626.nyc3.cdn.digitaloceanspaces.com/compressedvids/car_comp.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      {/* Video 2 */}
      <div className="z-10">
        <video className="h-[351px] w-[196px] aspect-[9/16] border-2 border-orange-500 rounded-md shadow-lg" autoPlay loop muted playsInline>
          <source src="https://delivery626.nyc3.cdn.digitaloceanspaces.com/compressedvids/0923(8)_comp.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      {/* Video 3 */}
      <div className="absolute transform rotate-12 translate-x-40 z-5">
        <video className="h-[300px] w-[168px] aspect-[9/16] border-2 border-cyan-300 rounded-md shadow-lg" autoPlay loop muted playsInline>
          <source src="https://delivery626.nyc3.cdn.digitaloceanspaces.com/compressedvids/0923(3)_comp.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default HeroVideoShowcase;
