import React from 'react';

const StepContainer = ({ stepNumber, title, description, children }) => (
  <div className="mb-6">
    <div className="flex items-center mb-2">
      <div className="bg-gradient-to-r from-blue-500 to-blue-700 text-white rounded-xl px-3 py-1 flex items-center justify-center mr-2 text-xs">
        <span className="font-medium">{stepNumber}</span>
      </div>
      <h2 className="text-2xl font-bold text-black">{title}</h2>
    </div>
    <p className="text-black mb-3 text-sm">{description}</p>
    {children}
  </div>
);

export default StepContainer;
