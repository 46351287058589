import React from 'react';
import { HiCheckCircle } from 'react-icons/hi2';

const TutorialSection = () => {
  return (
    <>
      <div className="hero bg-white pt-1 sm:pt-5 py-5 pb-10">
        <div className="max-w-7xl mx-auto px-4 lg:px-0">
          <div className="flex flex-col lg:flex-row-reverse justify-center items-center lg:space-x-10">
            <div className="w-full lg:w-2/5 overflow-hidden rounded-lg order-2 lg:order-none">
              <video src="https://delivery626.nyc3.cdn.digitaloceanspaces.com/compressedvids/themvus.mp4" className="w-full h-auto" autoPlay loop muted playsInline />
            </div>
            <div className="text-center lg:text-left lg:w-2/5 mt-8 lg:mt-0 order-1">
              <h1 className="text-3xl sm:text-4xl font-bold text-black pb-1 sm:pb-0 mb-4 sm:mb-0">
                Made <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-800">exactly</span> for social media
              </h1>
              <ul className="hidden sm:block py-5 text-black text-lg space-y-2">
                <li className="flex items-center">
                  <HiCheckCircle className="h-4 w-4 text-gray-700 mr-2" />
                  No need to crop videos
                </li>
                <li className="flex items-center">
                  <HiCheckCircle className="h-4 w-4 text-gray-700 mr-2" />
                  No watermarks
                </li>
                <li className="flex items-center">
                  <HiCheckCircle className="h-4 w-4 text-gray-700 mr-2" />
                  HD video outputs
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TutorialSection;
