import React from 'react';

const VidStats = () => {
  return (
    <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold text-gray-900 sm:text-4xl">
            We get <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-800">results</span>
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            @cutestfur went from 0 to 145k followers on TikTok in 30 days with VidStudio!
          </p>
        </div>
        <div className="flex flex-col items-center mb-12">
          <img
            className="h-32 w-32 rounded-full border-4 border-blue-300 shadow-lg mb-4"
            src="https://imagedelivery.net/Ze5D2t9VVJMFNTqhCbpFZg/ec25aadd-1dcf-42ee-47c8-ef8d9cb7e900/public"
            alt="@cutestfur profile picture"
          />
          <a href="https://www.tiktok.com/@cutestfur" target="_blank" rel="noopener noreferrer" className="text-black hover:text-blue-800 transition-colors duration-300 font-bold underline">@cutestfur</a>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-8 max-w-4xl mx-auto">
          <div className="bg-white rounded-lg shadow-md p-6 text-center transform hover:scale-105 transition-transform duration-300">
            <div className="text-lg font-semibold text-black mb-2">Followers Gained</div>
            <div className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-cyan-300 to-blue-800">145K+</div>
          </div>
          <div className="bg-white rounded-lg shadow-md p-6 text-center transform hover:scale-105 transition-transform duration-300">
            <div className="text-lg font-semibold text-black mb-2">Total Views</div>
            <div className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-cyan-300 to-blue-800">25M+</div>
          </div>
          <div className="bg-white rounded-lg shadow-md p-6 text-center transform hover:scale-105 transition-transform duration-300">
            <div className="text-lg font-semibold text-black mb-2">Total Likes</div>
            <div className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-cyan-300 to-blue-800">1.5M+</div>
          </div>
        </div>
        <p className="mt-8 text-xs text-gray-600 text-center">
          Results based on a brand new account's first 30 days only posting videos generated by VidStudio.
        </p>
      </div>
    </div>
  );
};

export default VidStats;
