import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import VideoTutorials from '../components/VideoTutorials';
import { HiOutlineSparkles } from 'react-icons/hi2';
import { fetchWithTokenRefresh } from '../utils/fetchWithTokenRefresh';

const Generate = () => {
  const [inputValue, setInputValue] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [credits, setCredits] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    const fetchCredits = async () => {
      try {
        const response = await fetchWithTokenRefresh('https://api.vidstudio.ai/user/credits', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json();
        if (response.ok) {
          setCredits(data.credits);
        } else {
          console.error('Failed to fetch credits:', data.message);
          setCredits('Error');
        }
      } catch (error) {
        console.error('Error fetching credits:', error);
        setCredits('Error');
      }
    };

    checkIfMobile();
    fetchCredits();
    window.addEventListener('resize', checkIfMobile);

    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  const handleCreate = async () => {
    setIsLoading(true);
    try {
      const response = await fetchWithTokenRefresh('https://api.vidstudio.ai/trigger-clip', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ input: inputValue }),
      });

      const data = await response.json();

      if (response.ok) {
        navigate('/dashboard/view');
      } else {
        alert(`Error: ${data.message}`);
      }
    } catch (error) {
      console.error('Error creating:', error);
      alert('Failed to create.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      {!isMobile && <Sidebar />}
      <div className="flex-1 p-8 md:p-12 lg:p-16 relative">
        {!isMobile && (
          <div className="absolute top-4 right-7 flex items-center space-x-4">
            {credits !== null && (
              <div className="bg-black text-white rounded-full px-3 py-1 text-sm font-semibold">
                {credits}
              </div>
            )}
            <Link to="/profile" className="text-sm text-black font-semibold hover:underline">
              Account
            </Link>
          </div>
        )}
        {isMobile && (
          <div className="flex justify-between mb-4">
            <button
              onClick={() => navigate('/dashboard/view')}
              className="bg-black text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-gray-900 transition-all duration-300"
            >
              Videos
            </button>
            <div className="flex items-center space-x-2">
              {credits !== null && (
                <div className="bg-black text-white rounded-full px-3 py-1 text-sm font-semibold">
                  {credits}
                </div>
              )}
              <button
                onClick={() => navigate('/profile')}
                className="bg-black text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-gray-900 transition-all duration-300"
              >
                Account
              </button>
            </div>
          </div>
        )}
        <div className="max-w-3xl mx-auto">
          <div className="bg-white shadow-lg rounded-2xl p-8 md:p-10 transition-all duration-300 hover:shadow-xl">
            <h2 className="text-3xl font-bold mb-2 text-gray-800 text-center">Create A Clip</h2>
            <p className="text-sm text-gray-600 text-center mb-8">Be as specific or generic as you want with your prompt. Our AI enhances every idea!</p>
            <div className="relative mb-6">
              <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                className="w-full border-2 border-gray-300 rounded-lg px-4 py-3 text-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-black transition-colors duration-200 bg-white"
                placeholder="Realistic video of a majestic lion standing on a log in the desert"
              />
            </div>
            <button
              onClick={handleCreate}
              className="w-full bg-black text-white font-semibold text-lg py-2 rounded-lg shadow-md hover:bg-gray-900 transition-all duration-300 transform hover:scale-105 flex items-center justify-center"
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="grid min-h-[12px] w-full place-items-center">
                  <svg className="text-gray-300 animate-spin" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                    <path
                      d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                      stroke="currentColor" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"></path>
                    <path
                      d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                      stroke="currentColor" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" className="text-gray-900"></path>
                  </svg>
                </div>
              ) : (
                <>
                  Create Magic
                  <HiOutlineSparkles className="h-4 w-4 ml-2 text-white" />
                </>
              )}
            </button>
          </div>
          
          <VideoTutorials />
        </div>
      </div>
    </div>
  );
};

export default Generate;
