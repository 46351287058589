import React from 'react';
import { HiOutlineSparkles, HiOutlineBolt, HiOutlineVideoCamera } from 'react-icons/hi2';

const CTA1 = () => {
  const scrollToPricing = () => {
    document.getElementById('pricing-sec').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="my-16 text-center px-4">
      <h2 className="text-3xl font-bold text-gray-900 mb-2">
        Ready to <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-800">Create</span>?
      </h2>
      <p className="text-lg text-gray-600 mb-4 max-w-2xl mx-auto">
        Get 55% off during our launch sale!
      </p>
      <button
        onClick={scrollToPricing}
        className="inline-flex items-center justify-center px-4 py-2 bg-gradient-to-r from-blue-500 to-blue-800 text-white text-sm font-bold rounded-md shadow-md hover:from-blue-600 hover:to-blue-900 transition-all duration-300 group w-full sm:w-auto mx-2 sm:mx-0"
      >
        <HiOutlineSparkles className="h-4 w-4 mr-1" />
        Start Creating Now
      </button>
    </div>
  );
};

const CTA2 = () => {
  const scrollToPricing = () => {
    document.getElementById('pricing-sec').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="my-16 text-center px-4">
      <h2 className="text-3xl font-bold text-gray-900 mb-2">
        Scale your <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-800">socials</span>
      </h2>
      <p className="text-lg text-gray-600 mb-4 max-w-2xl mx-auto">
        Get 55% off during our launch sale!
      </p>
      <button
        onClick={scrollToPricing}
        className="inline-flex items-center justify-center px-4 py-2 bg-gradient-to-r from-blue-500 to-blue-800 text-white text-sm font-bold rounded-md shadow-md hover:from-blue-600 hover:to-blue-900 transition-all duration-300 group w-full sm:w-auto mx-2 sm:mx-0"
      >
        <HiOutlineBolt className="h-4 w-4 mr-1" />
        Go Viral Now
      </button>
    </div>
  );
};

const CTA3 = () => {
  const scrollToPricing = () => {
    document.getElementById('pricing-sec').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="my-16 text-center px-4">
      <h2 className="text-3xl font-bold text-gray-900 mb-2">
        Say goodbye to <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-800">boring</span> videos
      </h2>
      <p className="text-lg text-gray-600 mb-4 max-w-2xl mx-auto">
        Explode your account with breathtaking clips!
      </p>
      <button
        onClick={scrollToPricing}
        className="inline-flex items-center justify-center px-4 py-2 bg-gradient-to-r from-blue-500 to-blue-800 text-white text-sm font-bold rounded-md shadow-md hover:from-blue-600 hover:to-blue-900 transition-all duration-300 group w-full sm:w-auto mx-2 sm:mx-0"
      >
        <HiOutlineVideoCamera className="h-4 w-4 mr-1" />
        Create Stunning Videos Now
      </button>
    </div>
  );
};

export { CTA1, CTA2, CTA3 };
