import React from 'react';
import { HiVideoCamera, HiSquares2X2 } from 'react-icons/hi2';

import Logo from '../icons/vslogo.png';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const navigationItems = [
  {
    name: 'Create Video',
    href: '/dashboard/generate',
    icon: HiVideoCamera,
    type: 'button', // This will be styled as a button
  },
  {
    name: 'Videos',
    href: '/dashboard/view',
    icon: HiSquares2X2,
    type: 'tab', // This will be styled as a tab
  },
  // Add other navigation items here...
];

export default function Sidebar() {
  return (
    <div className="flex flex-col flex-none gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 w-60">
      <div className="flex h-16 shrink-0 items-center">
        <img
          alt="Your Company"
          src={Logo}
          className="h-14 w-auto"
        />
      </div>
      <nav className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          {navigationItems.map((item) => (
            <li key={item.name}>
              {item.type === 'button' ? (
                <div className="relative">
                  <div className="absolute -inset-1 rounded-md bg-gradient-to-r from-blue-500 to-purple-600 opacity-50 blur"></div>
                  <a
                    href={item.href}
                    className="relative flex items-center justify-center gap-x-2 px-4 py-2 text-sm font-semibold leading-6 text-white bg-black rounded-md hover:bg-gray-800"
                  >
                    <item.icon className="h-5 w-5" aria-hidden="true" />
                    {item.name}
                  </a>
                </div>
              ) : (
                <a
                  href={item.href}
                  className="flex items-center gap-x-4 px-4 py-2 text-sm font-semibold leading-6 text-gray-900 rounded-md hover:bg-gray-50"
                >
                  <item.icon className="h-5 w-5" aria-hidden="true" />
                  {item.name}
                </a>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}