import React from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../icons/vslogo.png';

const Affiliate = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-white">
      {/* Logo at the top left corner */}
      <div className="absolute top-4 left-4 z-50">
        <img 
          src={Logo} 
          alt="Logo" 
          className="h-12 w-auto cursor-pointer" 
          onClick={() => navigate('/')}
        />
      </div>

      {/* Hero Section */}
      <div className="relative">
        <div className="max-w-6xl mx-auto px-6 sm:px-8 lg:px-12 py-10 pt-20 lg:py-24">
          <div className="text-center space-y-8">
            <h1 className="text-4xl sm:text-4xl md:text-5xl font-bold tracking-tight text-black">
              Join the <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-800">VidStudio</span> Partner Program
            </h1>
            <p className="mt-5 text-lg sm:text-xl text-black max-w-3xl mx-auto">
              Earn up to <span className="bg-clip-text font-bold text-transparent bg-gradient-to-r from-blue-400 to-blue-800">$232.80</span> per referral when you partner with VidStudio
            </p>
            <a 
              href="https://vid-studio.getrewardful.com/signup"
              className="inline-block mt-8 bg-blue-500 text-white px-5 sm:px-12 py-3 rounded-md text-sm font-medium hover:bg-blue-700 transition-colors duration-200"
            >
              Join Now
            </a>
          </div>
        </div>
      </div>

      {/* Benefits Section */}
      <div className="bg-gray-50 py-12">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-white rounded-lg p-4 sm:p-6 shadow-md hover:shadow-lg transition-shadow duration-200">
              <h3 className="text-lg sm:text-xl font-semibold mb-3">
                <span className="bg-gradient-to-br from-blue-500 via-blue-600 to-blue-700 bg-clip-text text-transparent">
                  Why join?
                </span>
              </h3>
              <p className="text-sm text-gray-600">
                Brand partnerships are one of the best ways to monetize your audience! You don't need to qualify for social media platform's strict creator payout programs.
              </p>
            </div>

            <div className="bg-white rounded-lg p-4 sm:p-6 shadow-md hover:shadow-lg transition-shadow duration-200">
              <h3 className="text-lg sm:text-xl font-semibold mb-3">
                <span className="bg-gradient-to-br from-blue-500 via-blue-600 to-blue-700 bg-clip-text text-transparent">
                  How do I make money as a Partner?
                </span>
              </h3>
              <p className="text-sm text-gray-600">
                All you have to do is recommend us using your unique link in your bio. We track your clicks and transactions so you can get paid.
              </p>
            </div>

            <div className="bg-white rounded-lg p-4 sm:p-6 shadow-md hover:shadow-lg transition-shadow duration-200">
              <h3 className="text-lg sm:text-xl font-semibold mb-3">
                <span className="bg-gradient-to-br from-blue-500 via-blue-600 to-blue-700 bg-clip-text text-transparent">
                  How much can I earn?
                </span>
              </h3>
              <p className="text-sm text-gray-600">
                You will earn 20% of all sales for up to 12 months that the user is subscribed. This can be up to $232.80 in commission on every referral that results in a successful sale. There's no limit to how much you can make by promoting VidStudio.
              </p>
            </div>

            <div className="bg-white rounded-lg p-4 sm:p-6 shadow-md hover:shadow-lg transition-shadow duration-200">
              <h3 className="text-lg sm:text-xl font-semibold mb-3">
                <span className="bg-gradient-to-br from-blue-500 via-blue-600 to-blue-700 bg-clip-text text-transparent">
                  What are the payout minimums?
                </span>
              </h3>
              <p className="text-sm text-gray-600">
                We require a minimum balance of $50.00 before processing a payout. We set this to avoid any fraudulent issues with our program.
              </p>
            </div>

            <div className="bg-white rounded-lg p-4 sm:p-6 shadow-md hover:shadow-lg transition-shadow duration-200">
              <h3 className="text-lg sm:text-xl font-semibold mb-3">
                <span className="bg-gradient-to-br from-blue-500 via-blue-600 to-blue-700 bg-clip-text text-transparent">
                  When do I get paid?
                </span>
              </h3>
              <p className="text-sm text-gray-600">
                We payout on Net 30 terms to account for refunds and chargebacks. For example, commissions generated in January would be paid out on March 15th (Net 30 days).
              </p>
            </div>

            <div className="bg-white rounded-lg p-4 sm:p-6 shadow-md hover:shadow-lg transition-shadow duration-200">
              <h3 className="text-lg sm:text-xl font-semibold mb-3">
                <span className="bg-gradient-to-br from-blue-500 via-blue-600 to-blue-700 bg-clip-text text-transparent">
                  How do I sign up?
                </span>
              </h3>
              <p className="text-sm text-gray-600">
                Click the "Join Now" button right below this and create an account. Start earning in less than 5 minutes.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-5xl mx-auto text-center">
          <h2 className="text-xl sm:text-2xl font-bold mb-6">
            <span className="text-black">
              Ready to Start Earning?
            </span>
          </h2>
          <a 
            href="https://vid-studio.getrewardful.com/signup"
            className="inline-block mt-3 bg-blue-500 text-white px-5 sm:px-12 py-3 rounded-md text-sm font-medium hover:bg-blue-700 transition-colors duration-200"
            >
            Join Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default Affiliate;
