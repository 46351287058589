import React, { useRef, useEffect } from 'react';

const LazyLoadVideo = ({ className, dataSrc, ...props }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const handleIntersection = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const video = entry.target;
          video.src = video.dataset.src; // Assign actual src for loading
          observer.unobserve(video); // Stop observing once loaded
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, observerOptions);

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <video
      ref={videoRef}
      className={className}
      data-src={dataSrc}
      {...props} // Spread other props to easily manage video attributes
    >
      Your browser does not support the video tag.
    </video>
  );
};

export default LazyLoadVideo;
