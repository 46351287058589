import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StepContainer from '../components/StepContainer';
import VoiceSelection from '../components/VoiceSelection';
import ThemeSelection from '../components/ThemeSelection';
import voicesData from '../JSON/voices.json'; // Import the voices JSON
import Sidebar from '../components/Sidebar'; // Import the Sidebar component
import { fetchWithTokenRefresh } from '../utils/fetchWithTokenRefresh'; // Import fetchWithTokenRefresh utility
import ArtStyle from '../components/ArtStyle'; // Import the ArtStyle component

const Create = () => {
  const voices = voicesData.voices; // Extract the array of voices

  // Data states
  const [destination, setDestination] = useState('');
  const [contentType, setContentType] = useState('');
  const [customPrompt, setCustomPrompt] = useState('');
  const [voice, setVoice] = useState('');
  const [artStyle, setArtStyle] = useState('');

  const navigate = useNavigate();

  const handleCreateSeries = async () => {
    try {
      const selectedVoice = voices.find(v => v.voice_id === voice);
      const voiceParam = selectedVoice && selectedVoice.name === 'Alloy' ? 'Alloy' : selectedVoice.voice_id;

      const response = await fetchWithTokenRefresh('https://api.vidstudio.ai/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          theme: contentType === 'Custom Topic' ? customPrompt : contentType,
          voice: voiceParam, // Include the selected voice id or name here
          artStyle,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        navigate(`/dashboard/view/`);
      } else {
        alert(`Error: ${data.message}`);
      }
    } catch (error) {
      console.error('Error creating series:', error);
      alert('Failed to create series.');
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-100">
      <Sidebar /> {/* Add the sidebar component */}
      <div className="flex-1 py-12 px-6">
        <div className="max-w-5xl space-y-6"> {/* Removed mx-auto to align containers to the left */}
          <div className="bg-white shadow-sm rounded-lg p-8">
            <StepContainer
              stepNumber={1}
              title="Destination"
              description="The account where your video series will be posted"
            >
              <select
                value={destination}
                onChange={(e) => setDestination(e.target.value)}
                className="w-full border border-gray-300 rounded px-3 py-2 mb-4 bg-white text-black"
              >
                <option value="">Select Account</option>
                <option value="email">Email Me Instead</option>
                {/* Add other accounts here */}
              </select>
            </StepContainer>
          </div>

          <div className="bg-white shadow-sm rounded-lg p-8">
            <StepContainer
              stepNumber={2}
              title="Content"
              description="What will your video series be about?"
            >
              <ThemeSelection
                selectedTheme={contentType}
                onThemeSelect={setContentType}
                customPrompt={customPrompt}
                setCustomPrompt={setCustomPrompt}
              />
            </StepContainer>
          </div>

          <div className="bg-white shadow-sm rounded-lg p-8">
            <StepContainer
              stepNumber={3}
              title="Video Settings"
              description="Preferences for the videos"
            >
              <VoiceSelection
                selectedVoice={voice}
                onVoiceSelect={setVoice}
              />
              <ArtStyle
                value={artStyle}
                onChange={setArtStyle}
              />
            </StepContainer>
          </div>

          <div className="flex items-center space-x-4">
            <button
              onClick={handleCreateSeries}
              className="bg-black text-white font-semibold text-sm px-4 py-2 rounded shadow-sm"
            >
              Create Video
            </button>
            <span className="text-gray-500 text-xs">You'll be able to preview the video before posting</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Create;
