import React from 'react';
import './App.css';
import LandingPage from './pages/LandingPage';
import Register from './pages/Register';
import Login from './pages/Login';
import SuccessPage from './pages/SuccessPage';
import Profile from './pages/Profile';
import Privacy from './pages/Privacy';
import Create from './pages/Create';
import View from './pages/View';
import Generate from './pages/Generate';
import Affiliate from './pages/Affiliate';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

function App() {
  const isAuthenticated = () => {
    // Check if the user is authenticated by verifying the presence of an access token
    return !!localStorage.getItem('accessToken');
  };

  const ProtectedRoute = ({ element }) => {
    return isAuthenticated() ? element : <Navigate to="/login" />;
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/profile" element={<ProtectedRoute element={<Profile />} />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/partner" element={<Affiliate />} />
          <Route path="/affiliate" element={<Navigate to="/partner" replace />} />
          <Route path="/dashboard/create" element={<ProtectedRoute element={<Create />} />} />
          <Route path="/dashboard/view" element={<ProtectedRoute element={<View />} />} />
          <Route path="/dashboard/generate" element={<ProtectedRoute element={<Generate />} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
