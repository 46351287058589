// src/components/VideoSlider.js
import React from 'react';
import LazyLoadVideo from '../LazyLoadVideo.js'; // Import the LazyLoadVideo component

const VideoSlider = () => {
  const videoUrls = [
    "https://delivery626.nyc3.cdn.digitaloceanspaces.com/compressedvids/0923(11)_comp.mp4",
    "https://delivery626.nyc3.cdn.digitaloceanspaces.com/compressedvids/0923(7)_comp.mp4",
    "https://delivery626.nyc3.cdn.digitaloceanspaces.com/compressedvids/0923(1)_comp.mp4",
    "https://delivery626.nyc3.cdn.digitaloceanspaces.com/compressedvids/0923(9)_comp.mp4",
  ];

  const borderColors = ['border-blue-800', 'border-green-800', 'border-red-800', 'border-yellow-800'];

  return (
    <div className="relative bg-gray-50 px-4 md:px-0 overflow-hidden pb-8 md:pb-12">
      <h2 className="text-2xl md:text-3xl font-bold mb-2 text-black mt-6 md:mt-8 text-center">Incredible videos for <span className="underline italic">any</span> audience</h2>
      <p className="text-xs md:text-sm text-gray-600 mb-6 md:mb-8 text-center">Our videos are proven to resonate across many niches and platforms</p>
      <div className="video-slider relative flex justify-center items-center overflow-hidden mb-6 md:mb-8">
        <div className="grid grid-cols-2 gap-2 md:gap-4 md:flex md:space-x-4 lg:space-x-16">
          {videoUrls.map((url, index) => (
            <div key={index} className="flex-shrink-0">
              <LazyLoadVideo
                className={`w-full h-auto md:h-[300px] lg:h-[401px] md:w-[168px] lg:w-[223.33px] aspect-[9/16] border-2 ${borderColors[index]} rounded-md shadow-lg`}
                autoPlay
                loop
                muted
                playsInline
                dataSrc={url} // Use dataSrc prop for lazy loading
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VideoSlider;
