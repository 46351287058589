import React from 'react';
import { Link } from 'react-router-dom';
import { HiStar, HiDevicePhoneMobile, HiOutlineSparkles, HiOutlineBolt, HiOutlineFilm } from 'react-icons/hi2';
import HeroVideoShowcase from './HeroVideoShowcase';

const HeroSection = () => {
  const scrollToPricing = () => {
    document.getElementById('pricing-sec').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="relative bg-white overflow-hidden">
      <div className="max-w-8xl mx-auto px-4 lg:px-0">
        {/* Hero Section for Desktop */}
        <div className="hero min-h-[5vh] bg-white pt-3 sm:pt-10 hidden md:block overflow-hidden">
          <div className="flex flex-col lg:flex-row-reverse justify-center items-center">
            {/* Replace hero video with HeroVideoShowcase */}
            <HeroVideoShowcase />

            <div className="text-left lg:mr-56 mt-8 lg:mt-0">
              {/* Rating Component */}
              <div className="inline-flex items-center mb-4 bg-blue-50 rounded-full px-4 py-2 shadow-sm relative">
                {[...Array(5)].map((_, index) => (
                  <HiStar key={index} className="h-5 w-5 text-yellow-400" />
                ))}
                <span className="ml-2 text-sm font-medium text-black">Rated 4.9/5.0</span>
                <div className="absolute -right-24 top-1/2 transform -translate-y-1/2 flex items-center">
                  <div className="relative mr-2">
                    <div className="w-2 h-2 bg-green-500 rounded-full animate-ping"></div>
                    <div className="w-2 h-2 bg-green-500 rounded-full absolute top-0"></div>
                  </div>
                  <span className="text-xs font-semibold text-green-500">AI Updated</span>
                </div>
              </div>
              <h1 className="text-5xl lg:text-7xl font-bold leading-none text-black">
                Turn text into
              </h1>
              <h1 className="text-5xl lg:text-7xl font-bold leading-none">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-800">stunning</span>
                <span className="text-black"> videos</span>
              </h1>
              <p className="py-5 text-black text-xl">Create hyper-realistic AI clips that are designed to go <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-blue-800">viral</span></p>
              <button onClick={scrollToPricing} className="btn text-white bg-gradient-to-r from-blue-500 to-blue-800 hover:from-blue-500 hover:to-blue-700 focus:outline-none w-48 py-2 cursor-pointer">Start Now</button>
            </div>
          </div>
        </div>

        {/* Hero Section for Mobile */}
        <div className="hero min-h-[5vh] bg-white pt-3 sm:pt-10 block md:hidden">
          <div className="flex flex-col justify-between items-center">
            <div className="text-left">
              {/* Rating Component */}
              <div className="inline-flex items-center mb-4 bg-blue-50 rounded-full px-4 py-2 shadow-sm relative">
                {[...Array(5)].map((_, index) => (
                  <HiStar key={index} className="h-5 w-5 text-yellow-400" />
                ))}
                <span className="ml-2 text-sm font-medium text-black">Rated 4.9/5.0</span>
                <div className="absolute -right-24 top-1/2 transform -translate-y-1/2 flex items-center">
                  <div className="relative mr-2">
                    <div className="w-2 h-2 bg-green-500 rounded-full animate-ping"></div>
                    <div className="w-2 h-2 bg-green-500 rounded-full absolute top-0"></div>
                  </div>
                  <span className="text-xs font-semibold text-green-500">AI Updated</span>
                </div>
              </div>
              <h1 className="text-5xl font-bold leading-none text-black">
                Turn text into
              </h1>
              <h1 className="text-5xl font-bold leading-none">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-800">stunning</span>
                <span className="text-black"> videos</span>
              </h1>
              <p className="py-5 text-black text-md">Create hyper-realistic AI clips that are designed to go <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-blue-800">viral</span></p>
            </div>
            {/* Replace hero video with HeroVideoShowcase */}
            <HeroVideoShowcase />
            <div className="max-w-6xl mx-auto px-4 sm:px-6 py-1 mt-4">
              <div className="text-sm grid grid-cols-2 gap-4 py-4 font-bold">
                <div className="flex w-full max-w-xs items-center space-x-2">
                  <HiDevicePhoneMobile className="h-6 w-6 text-blue-600 flex-shrink-0" />
                  <span className="text-black text-left">Sized for Socials</span>
                </div>
                <div className="flex w-full max-w-xs items-center space-x-2">
                  <HiOutlineSparkles className="h-6 w-6 text-blue-600 flex-shrink-0" />
                  <span className="text-black text-left">Proven Virality</span>
                </div>
                <div className="flex w-full max-w-xs items-center space-x-2">
                  <HiOutlineBolt className="h-6 w-6 text-blue-600 flex-shrink-0" />
                  <span className="text-black text-left">Videos in Minutes</span>
                </div>
                <div className="flex w-full max-w-xs items-center space-x-2">
                  <HiOutlineFilm className="h-6 w-6 text-blue-600 flex-shrink-0" />
                  <span className="text-black text-left">HD Videos</span>
                </div>
              </div>
            </div>
            <button onClick={scrollToPricing} className="btn text-white bg-gradient-to-r from-blue-500 to-blue-800 hover:from-blue-500 hover:to-blue-700 focus:outline-none w-full py-2 cursor-pointer my-4">Start Now</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
