import React from 'react';

const FAQs = () => {
  return (
    <>
      <div className="text-4xl font-bold mb-8 text-black max-w-2xl mx-auto px-4 pt-5 sm:px-6 text-center">Frequently Asked Questions</div>
      <div className="collapse text-black max-w-2xl mx-auto px-4 sm:px-6 py-1 border border-blue-600 shadow-md mb-4">
        <input type="checkbox" /> 
        <div className="collapse-title text-xl font-medium">
          What type of content can I create?
        </div>
        <div className="collapse-content"> 
          <p className="font-bold">Anything you can imagine! Our video AI model is powerful enough to generate just about any style or scene!</p>
        </div>
      </div>
      <div className="collapse text-black bg-white max-w-2xl mx-auto px-4 sm:px-6 py-1 border border-blue-600 shadow-md mb-4">
        <input type="checkbox" /> 
        <div className="collapse-title text-xl font-medium">
          What if I need to cancel?
        </div>
        <div className="collapse-content"> 
          <p className="font-bold">You can easily cancel with one click! No emails, no waiting, just click and you won't be charged again.</p>
        </div>
      </div>
      <div className="collapse text-black bg-white max-w-2xl mx-auto px-4 sm:px-6 py-1 border border-blue-600 shadow-md mb-20">
        <input type="checkbox" /> 
        <div className="collapse-title text-xl font-medium">
          What if I want a refund?
        </div>
        <div className="collapse-content"> 
          <p className="font-bold">We offer a 3-day guarentee! If you are unsatisfied with your subscription send us an email and we will provide a full refund for any unused credits.</p>
        </div>
      </div>
    </>
  );
};

export default FAQs;
