import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../icons/vslogo.png';
import { fetchWithTokenRefresh } from '../utils/fetchWithTokenRefresh';

const Tooltip = ({ onClose, onConfirm }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg relative max-w-sm mx-auto">
        <button className="absolute top-0 right-0 text-2xl font-semibold p-4" onClick={onClose}>×</button>
        <div className="text-center">
          <div className="text-6xl mb-4">😢</div>
          <h2 className="text-2xl font-bold mb-4">We hate to see you go!</h2>
          <p className="mb-8">Are you sure you'd like to cancel?</p>
          <div className="flex justify-center">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-10 rounded mr-2" onClick={onClose}>No</button>
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-10 rounded ml-2" onClick={onConfirm}>Yes</button>
          </div>
        </div>
      </div>
    </div>
  );
};

const MessagePopup = ({ content, onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg relative max-w-sm mx-auto">
      <button className="absolute top-0 right-0 text-2xl font-semibold p-4" onClick={onClose}>×</button>
      <div className="text-center">
        <div className="text-6xl mb-4">🎉</div>
        <h2 className="text-2xl font-bold mb-4">Subscription Status</h2>
        <div className="mb-8" dangerouslySetInnerHTML={{ __html: content }}></div>
        <div className="flex justify-center">
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-10 rounded" onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  </div>
);

const Profile = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const [messagePopupContent, setMessagePopupContent] = useState('');
  const [credits, setCredits] = useState(null);

  useEffect(() => {
    const fetchCredits = async () => {
      try {
        const response = await fetchWithTokenRefresh('https://api.vidstudio.ai/user/credits', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json();
        if (response.ok) {
          setCredits(data.credits);
        } else {
          console.error('Failed to fetch credits:', data.message);
          setCredits('Error');
        }
      } catch (error) {
        console.error('Error fetching credits:', error);
        setCredits('Error');
      }
    };

    fetchCredits();
  }, []);

  const handleCancelClick = () => {
    setShowTooltip(true);
  };

  const handleTooltipClose = () => {
    setShowTooltip(false);
  };

  const handleTooltipConfirm = async () => {
    try {
      const response = await fetchWithTokenRefresh('https://api.vidstudio.ai/cancel-subscription', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      if (response.ok) {
        console.log('Subscription cancelled successfully:', data);
        setMessagePopupContent('Your subscription was cancelled successfully');
      } else {
        console.error('Failed to cancel subscription:', data.error);
        setMessagePopupContent(`Sorry! There was an issue, try again or contact us at <a href="mailto:support@vidstudio.ai" class="underline text-blue-600">support@vidstudio.ai</a>.`);
      }
      setShowTooltip(false);
      setShowMessagePopup(true);
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      setMessagePopupContent(`An error occurred. Please try again or contact support.`);
      setShowTooltip(false);
      setShowMessagePopup(true);
    }
  };

  return (
    <div className="bg-gray-100 text-gray-800 min-h-screen">
      <nav className="bg-white shadow">
        <div className="max-w-6xl mx-auto px-4">
          <div className="flex justify-between">
            <div className="flex space-x-4">
              <div>
                <Link to="/dashboard/generate" className="flex items-center py-5 px-2 text-gray-700 hover:text-gray-900">
                  <img src={Logo} alt="Logo" className="h-14 w-auto" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div className="max-w-4xl mx-auto py-8 px-4">
        <div className="bg-white p-4 rounded-lg shadow mb-4 inline-block">
          <span className="text-lg font-semibold mr-2">Credits Remaining:</span>
          <span className="text-xl font-bold text-blue-600">
            {credits !== null ? credits.toLocaleString('en-US') : 'Loading...'}
          </span>
        </div>
        <div className="bg-white p-6 rounded-lg shadow">
          <div>
            <h2 className="text-2xl font-bold mb-2 text-gray-800">Billing</h2>
            <div className="mt-4 flex space-x-4">
              <button onClick={handleCancelClick} className="bg-black hover:bg-gray-800 text-white text-sm font-semibold py-2 px-4 rounded transition duration-100 ease-in-out">
                Cancel Subscription
              </button>
              <a href="mailto:support@vidstudio.ai" className="bg-blue-700 hover:bg-blue-600 text-white text-sm font-semibold py-2 px-4 rounded transition duration-100 ease-in-out">
                Contact Us
              </a>
            </div>
          </div>
          {showTooltip && <Tooltip onClose={handleTooltipClose} onConfirm={handleTooltipConfirm} />}
          {showMessagePopup && <MessagePopup content={messagePopupContent} onClose={() => setShowMessagePopup(false)} />}
        </div>
      </div>
    </div>
  );
};

export default Profile;
