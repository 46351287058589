import React from 'react';

const Banner = () => {
  return (
    <div className="flex items-center justify-center bg-black px-6 py-2.5 sm:px-3.5 relative">
      <p className="text-sm leading-6 text-white text-center">
        <a href="#">
          <strong className="font-semibold">🎉 Launch Sale! 🎉</strong>
          <svg viewBox="0 0 2 2" aria-hidden="true" className="mx-2 inline h-0.5 w-0.5 fill-current">
            <circle r={1} cx={1} cy={1} />
          </svg>
          55% OFF + 1k Credit Bonus!<span aria-hidden="true"></span>
        </a>
      </p>
    </div>
  );
};

export default Banner;
