// utils/fetchWithTokenRefresh.js

export const fetchWithTokenRefresh = async (url, options = {}, retry = true) => {
    let accessToken = localStorage.getItem('accessToken');
    let response = await fetch(url, {
      ...options,
      headers: {
        ...options.headers,
        'Authorization': `Bearer ${accessToken}`,
      },
    });
  
    if (response.status === 401 && retry) {
      const refreshToken = localStorage.getItem('refreshToken');
      if (refreshToken) {
        // Attempt to refresh the token
        const refreshResponse = await fetch('https://api.vidstudio.ai/refresh-token', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ refreshToken }),
        });
  
        if (refreshResponse.ok) {
          const refreshData = await refreshResponse.json();
          localStorage.setItem('accessToken', refreshData.accessToken);
          localStorage.setItem('idToken', refreshData.idToken);
          accessToken = refreshData.accessToken;
  
          // Retry the original request with the new access token
          response = await fetch(url, {
            ...options,
            headers: {
              ...options.headers,
              'Authorization': `Bearer ${accessToken}`,
            },
          });
        } else {
          throw new Error('Failed to refresh token');
        }
      } else {
        throw new Error('Refresh token not available');
      }
    }
  
    return response;
  };
  